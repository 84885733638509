import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_LabelCustom = _resolveComponent("LabelCustom")!
  const _component_ResponsiveLayout = _resolveComponent("ResponsiveLayout")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmationDialog, {
      title: "Bạn có chắc chắn muốn xóa dữ liệu này?",
      width: "20%",
      "is-display": _ctx.isDeleteVatTuDialogDisplay,
      onOnConfirmButtonClick: _ctx.confirmDelete,
      onOnCloseButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDeleteVatTuDialogDisplay = false)),
      onOnCloseIconClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDeleteVatTuDialogDisplay = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.supply.name), 1)
      ]),
      _: 1
    }, 8, ["is-display", "onOnConfirmButtonClick"]),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode(" Thông tin ")
      ]),
      "page-row-action-button": _withCtx(() => [
        (!_ctx.isInaccessible)
          ? (_openBlock(), _createBlock(_component_ButtonCustom, {
              key: 0,
              "color-type": 'success',
              "button-type": 'EDIT',
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.push(`/quan-ly/vat-tu/cap-nhat/${_ctx.supply.id}`)))
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ButtonCustom, {
          "color-type": 'primary',
          "button-type": 'BACK',
          onClick: _ctx.goBack
        }, null, 8, ["onClick"])
      ]),
      "page-row-content": _withCtx(() => [
        _createVNode(_component_ResponsiveLayout, { "margin-y": "my-2" }, {
          "col-1": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" ID: ")
              ]),
              _: 1
            })
          ]),
          "col-2": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supply.id), 1)
              ]),
              _: 1
            })
          ]),
          "col-3": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Tên vật tư: ")
              ]),
              _: 1
            })
          ]),
          "col-4": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supply.name), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ResponsiveLayout, { "margin-y": "my-2" }, {
          "col-1": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Mã vật tư: ")
              ]),
              _: 1
            })
          ]),
          "col-2": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supply.code), 1)
              ]),
              _: 1
            })
          ]),
          "col-3": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Đơn vị tính: ")
              ]),
              _: 1
            })
          ]),
          "col-4": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supply.unitString ? _ctx.supply.unitString : "-"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ResponsiveLayout, { "margin-y": "my-2" }, {
          "col-1": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Mức dự trữ tối thiểu: ")
              ]),
              _: 1
            })
          ]),
          "col-2": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatCurrencyAndQuantity(_ctx.supply.minimumReserve)), 1)
              ]),
              _: 1
            })
          ]),
          "col-3": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Mức dự trữ tối đa: ")
              ]),
              _: 1
            })
          ]),
          "col-4": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatCurrencyAndQuantity(_ctx.supply.maximumReserve)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ResponsiveLayout, { "margin-y": "my-2" }, {
          "col-1": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Thời gian tạo: ")
              ]),
              _: 1
            })
          ]),
          "col-2": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supply.createdAt), 1)
              ]),
              _: 1
            })
          ]),
          "col-3": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Thời gian cập nhật: ")
              ]),
              _: 1
            })
          ]),
          "col-4": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-bolder": true }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supply.updatedAt), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ResponsiveLayout, { "margin-y": "my-2" }, {
          "col-1": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Thu hồi?: ")
              ]),
              _: 1
            })
          ]),
          "col-2": _withCtx(() => [
            _createVNode(_component_el_checkbox, {
              id: "is-thu-hoi",
              modelValue: _ctx.supply.recall,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.supply.recall) = $event)),
              disabled: "",
              size: "large"
            }, null, 8, ["modelValue"])
          ]),
          "col-3": _withCtx(() => [
            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
              default: _withCtx(() => [
                _createTextVNode(" Công ty đã duyệt? ")
              ]),
              _: 1
            })
          ]),
          "col-4": _withCtx(() => [
            _createVNode(_component_el_checkbox, {
              id: "is-cong-ty-duyet",
              modelValue: _ctx.supply.isApprovedByComp,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.supply.isApprovedByComp) = $event)),
              disabled: "",
              size: "large"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}